import posthog from 'posthog-js';
import { useEffect } from 'react';
import { useCurrentUserQuery } from '../generated/graphql';

let userIdentified = false;

export function useCurrentUser() {
  const { data } = useCurrentUserQuery();

  const { id, email } = data?.currentUser?.user || {};

  useEffect(() => {
    if (!userIdentified && id && email) {
      posthog.identify(id, {
        email,
        buildId: window.__NEXT_DATA__?.buildId,
      });
      userIdentified = true;
    }
  }, [id, email]);

  return data?.currentUser?.user;
}

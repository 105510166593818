export enum ShipmentTypes {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export enum ExternalCarrierCode {
  dhlUK = 'dhl-uk',
}

export const SUPPORTED_CARRIERS = [ExternalCarrierCode.dhlUK];

export function formatCarrierName(carrierCode: ExternalCarrierCode | string) {
  switch (carrierCode) {
    case ExternalCarrierCode.dhlUK:
      return 'DHL';
    default:
      return carrierCode;
  }
}
